import React from "react";

import axios from "axios";

import { useParams, useNavigate } from "react-router-dom";

import { AiFillCheckSquare } from "react-icons/ai";
import { GiCrossMark, GiCheckMark } from "react-icons/gi";

import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import A from "../../utils/API";

export default function VideoWithQuizResult() {
  const navigate = useNavigate();
  const { closeWindow, eventLog } = useUtils();
  const { user, headers } = useUser();
  const { id } = useParams();

  const [data, setData] = React.useState(false);

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.ANSWER_RESULT_MULTI,
        { quizId: id },
        headers
      );
      setData(data);
    } catch (err) {
      console.log(err);
      closeWindow();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col z-10">
      <div className="text-black h-full w-full flex justify-center items-center">
        <div className="flex flex-col items-center rounded-md w-[90vw] bg-white p-3">
          <img className="h-24 object-cover" src="/img/quiz.png" alt="" />
          <p className="mt-2 font-bold text-xl text-center max-w-[80%]">
            You have successfully completed the quiz
          </p>
          <p className="text-lg mt-5">Your Answer is</p>
          <div className="mt-2 flex flex-col gap-y-1">
            {data &&
              data?.map((each, idx) => (
                <p
                  style={{
                    color: each?.answerType === "RIGHT" ? "green" : "red",
                  }}
                  className="flex items-center text-lg"
                >
                  {idx + 1} Question
                  {each?.answerType === "RIGHT" ? (
                    <GiCheckMark className="ml-1 text-2xl" />
                  ) : (
                    <GiCrossMark className="ml-1 text-2xl" />
                  )}
                </p>
              ))}
          </div>
          <p className="mb-5 mt-2 text-lg text-center max-w-[80%]">
            The Reward Amount will be announced anytime before 11:55 PM
          </p>
          <button
            onClick={() => {
              navigate("/upi/" + id);
              eventLog("CLAIM_CLICK", id);
              // playClick();
            }}
            className="max-w-[80vw] bg-yellow-500 md:w-[500px] sm:w-[90vw] py-3 rounded-lg text-lg text-bold font-bold my-5"
          >
            Get My Payment
          </button>
        </div>
      </div>
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
    </div>
  );
}
