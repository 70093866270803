import React from "react";
import { BiRupee } from "react-icons/bi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function CampaignComplete() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://campaign.pepul.com/pepul-quiz/";
    }, 10000);
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen my-auto">
      <img
        src="/img/dialog-bg.jpeg"
        className="fixed w-screen object-cover inset-0 bg-blue-500"
        style={{
          zIndex: -20,
        }}
      />
      {/* <div
    style={{
      zIndex: -1,
    }}
    className="fixed opacity-75 h-screen w-screen bg-blue-500"
  ></div> */}
      <img className="mt-24 h-32" src="/img/quiz.png" alt="" />
      {/* <img className="h-32" src="/img/success.gif" alt="" /> */}
      {searchParams.get("result") === "YES" ? (
        <h1 className="text-xl">You won</h1>
      ) : (
        <>
          <h1 className="text-lg">Oops! You Lost</h1>
          <h1 className="text-sm mb-2">But We give you</h1>
        </>
      )}
      {searchParams.get("result") === "YES" ? (
        <div className="font-bold text-4xl text-yellow-500 flex items-center">
          <BiRupee />
          <p>{searchParams.get("amount")}</p>
        </div>
      ) : (
        <div className="font-bold text-3xl text-yellow-500 flex items-center">
          <BiRupee />
          <p>{searchParams.get("amount")}</p>
        </div>
      )}
      <h2 className="mt-5 text-white text-center font-bold text-xl sm:text-lg mb-5">
        🎉 Thank you for your participation 🎉
      </h2>
      <p className="max-w-[80vw] text-center text-sm">
        Payment Link has been sent to your Phone Number. Please Complete the
        link and get your payment.
      </p>
      <a target={"_blank"} href={"https://campaign.pepul.com/pepul-quiz/"}>
        <button className="mt-2 font-bold border-none bg-yellow-500 text-black text-lg w-[60vw] h-12 rounded-md">
          Play More
        </button>
      </a>
      {/* <div className="mt-10 flex">
        <a
          href="https://www.pepul.com/quizten"
          className="flex flex-col text-center text-xs bg-blue-500 rounded-md p-5"
        >
          <img className="h-10 my-2" src="/img/100 2.png" alt="" />
          <p>Watch & Win</p>
          <p>Upto</p>
          <p className="text-xl text-yellow-500 font-bold my-2">₹100</p>
          <button className="bg-yellow-500 text-black px-2 py-1 rounded-md font-bold mb-2">
            Pay ₹10
          </button>
          <p>Participate Now</p>
        </a>
        <a
          href="https://www.pepul.com/quizthirty"
          className="flex flex-col text-center text-xs bg-blue-500 rounded-md p-5"
        >
          <img className="h-10 my-2" src="/img/200.png" alt="" />
          <p>Watch & Win</p>
          <p>Upto</p>
          <p className="text-xl text-yellow-500 font-bold my-2">₹300</p>
          <button className="bg-yellow-500 text-black px-2 py-1 rounded-md font-bold mb-2">
            Pay ₹30
          </button>
          <p>Participate Now</p>
        </a>
        <a
          href="https://www.pepul.com/quizfifty"
          className="flex flex-col text-center text-xs bg-blue-500 rounded-md p-5"
        >
          <img className="h-10 my-2" src="/img/500.png" alt="" />
          <p>Watch & Win</p>
          <p>Upto</p>
          <p className="text-xl text-yellow-500 font-bold my-2">₹500</p>
          <button className="bg-yellow-500 text-black px-2 py-1 rounded-md font-bold mb-2">
            Pay ₹50
          </button>
          <p>Participate Now</p>
        </a>
      </div> */}
      <div className="mt-5 flex justify-between px-10 bg-blue-500 w-full p-5">
        <div className="flex flex-col">
          <p>To play more quiz like this,</p>
          <p>Download the Pepul App</p>
        </div>
        <img className="mt-2 h-10" src="/img/pepul-logo.svg" alt="" />
      </div>
      <div className="mb-10 flex gap-x-4 pt-5 bg-blue-500">
        <a
          target={"_blank"}
          href="https://play.google.com/store/apps/details?id=com.pepul.socialnetworking&hl=en_IN&gl=US"
        >
          <img src="/img/google-play-badge 1.svg" alt="" />
        </a>
        <a
          target={"_blank"}
          href="https://apps.apple.com/in/app/pepul-social-network-app/id1571517301"
        >
          <img src="/img/Apple.svg" alt="" />
        </a>
      </div>
    </div>
  );
}
