import React from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import ReactPlayer from "react-player";
import axios from "axios";

import { format } from "date-fns";

import { BiRupee } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";

import A from "../utils/API";

import useLang from "../hooks/useLang";
import useUser from "../hooks/useUser";
import useUtils from "../hooks/useUtils";

import { IoIosArrowBack } from "react-icons/io";
import Results from "../components/Results";

const Timer = ({
  startTime = new Date(),
  endTime = new Date(),
  serverTime = new Date(),
  onComplete = () => {},
  onParticipateClick = () => {},
}) => {
  const navigate = useNavigate();
  const { lang, switchLang, activeLang } = useLang();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <>
          <button
            onClick={onParticipateClick}
            className="mt-5 font-bold text-black text-xl bg-white w-[300px] py-3 rounded-md"
          >
            {lang?.PARTICIPATE}
          </button>
          {/* <p>{endTime}</p> */}
        </>
      );
    } else {
      // Render a countdown
      return (
        <div className="flex flex-col items-center">
          <p className="mt-5 text-xs font-medium">{lang?.TIME_LEFT}</p>
          <div className="flex my-2">
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                <p>{zeroPad(hours)}</p>
                <p className="text-xs text-white opacity-50">{lang?.HOURS}</p>
              </div>
            </p>
            <p>:</p>
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                <p>{zeroPad(minutes)}</p>
                <p className="text-xs text-white opacity-50">{lang?.MINUTES}</p>
              </div>
            </p>
            <p>:</p>
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                <p>{zeroPad(seconds)}</p>
                <p className="text-xs text-white opacity-50">{lang?.SECONDS}</p>
              </div>
            </p>
          </div>
          {/* <p className="mt-2 text-xs font-medium">
            {lang?.DAILY_QUIZ} <b>8:00 pm to 8:05 pm</b>
          </p> */}
        </div>
      );
    }
  };
  return (
    <div className="text-2xl font-bold flex gap-x-4">
      <Countdown
        precision={new Date().getTime() - new Date(serverTime).getTime()}
        onComplete={onComplete}
        date={startTime}
        renderer={renderer}
      />
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { closeWindow, eventLog, addSuffix, playClick } = useUtils();

  const [parti, setParti] = React.useState(false);

  const [selectedSection, setSelectedSection] = React.useState("QUIZ");

  const { lang, switchLang, activeLang } = useLang();
  const { user, setUser, headers } = useUser();

  const [showClaimMoney, setClaimMoney] = React.useState(false);

  // if (!user) navigate("/error");

  const divRef = React.useRef(null);

  const [data, setData] = React.useState(false);

  const validateUser = () =>
    axios
      .post(A.HOST + A.VALIDATE_USER, {}, headers)
      .then(({ data }) => {
        if (data) setUser(data);
      })
      .catch((err) => {
        closeWindow();
        setUser(null);
      });

  React.useEffect(() => {
    if (user) {
      validateUser(user?.accessToken);
      fetchHomeData();
      eventLog("VISITED");
    }
  }, [user?.accessToken]);

  React.useEffect(() => {
    if (user?.userId && A.PHP_UPDATE)
      axios
        .post(A.PHP_UPDATE, { userId: user?.userId })
        .then((data) => console.log(JSON.stringify(data, null, 4)));
  }, [user]);

  const fetchHomeData = () =>
    axios
      .post(A.HOST + A.HOME_DATA_MULTI, {}, headers)
      .then(({ data }) => {
        if (data?.goToPage === "UPI" && data?.uncompleteCount === 0)
          // navigate("/upi/" + data?.goToPageQuizId);
          setClaimMoney(data?.goToPageQuizId);
        setData(data);
      })
      .catch((err) => {
        closeWindow();
      });

  const onBlur = () => {
    closeWindow();
  };

  React.useEffect(() => {
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  // React.useEffect(() => {
  //   const closeWindow = () =>
  //     setTimeout(() => {
  //       window.open("about:blank", "_self");
  //       window.close();
  //     }, 3000);
  //   closeWindow();
  // }, []);

  React.useEffect(() => {
    if (!searchParams.get("page")) setSearchParams({ page: "QUIZ" });
  }, []);

  return (
    <>
      <div ref={divRef} className="h-screen w-screen flex flex-col z-10">
        <div className="flex items-center justify-between w-full">
          <IoIosArrowBack
            onClick={() => {
              closeWindow();
            }}
            className="m-2 mb-1 text-4xl"
          />
          {/* <div className="cursor-pointer m-2 mb-1 mr-3 text-sm">
            {activeLang === "en" ? (
              <p
                onClick={() => {
                  switchLang("ta");
                  eventLog("LANG_SWITCH_ENG");
                }}
              >
                தமிழுக்கு மாறுங்கள்
              </p>
            ) : (
              <p
                onClick={() => {
                  switchLang("en");
                  eventLog("LANG_SWITCH_TAM");
                }}
              >
                Switch to English
              </p>
            )}
          </div> */}
        </div>
        <div
          className="z-10 sticky top-0 text-white rounded-md flex mx-10 bg-white"
          style={{
            backgroundColor: "#366092",
          }}
        >
          <p
            onClick={() =>
              setSearchParams({
                page: "QUIZ",
              })
            }
            style={{
              borderBottomWidth: searchParams.get("page") === "QUIZ" ? 2 : 0,
            }}
            className="font-bold w-1/2 p-2 flex justify-center items-center"
          >
            Quiz
          </p>
          <p
            // onClick={() => setSelectedSection("RESULTS")}
            onClick={() => {
              // navigate("/home?page=RESULTS");
              eventLog("RESULTS_CLICK");
              setSearchParams({
                page: "RESULTS",
              });
            }}
            style={{
              borderBottomWidth: searchParams.get("page") === "RESULTS" ? 2 : 0,
            }}
            className="font-bold w-1/2 p-2 flex justify-center items-center"
          >
            My Results
          </p>
          {/* <p
            onClick={() => setSelectedSection("LEADERBOARD")}
            style={{
              borderBottomWidth: selectedSection === "LEADERBOARD" ? 2 : 0,
            }}
            className="w-1/3 p-2 flex justify-center items-center"
          >
            Leaderboard
          </p> */}
        </div>
        {searchParams.get("page") === "QUIZ" ? (
          <div className="-mt-2 pb-10 gap my-5 mt-0 flex flex-col justify-center items-center">
            <img className="h-40" src="/img/quiz.png" alt="" />
            <p className="mt-1 font-bold text-center text-2xl">
              {lang?.WATCH_WIN_DAILY}
            </p>
            <p className="mt-1 font-bold text-center text-md">Upto</p>
            <img className="mt-3 h-10" src="/img/10000.png" alt="" />
            <p className="text-lg mt-2">{lang?.HOW_TO_PARTICIPATE}</p>
            <div className="px-5">
              <video
                className="mt-5 w-full w-[90vw] max-w-[600px] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
                // autoPlay
                // muted
                poster="https://d3dnd7j639hmiz.cloudfront.net/banners/55261ae6-9301-4276-8616-41f55e3fa1b5.png"
                controlsList="nodownload"
                loop
                controls
              >
                <source
                  src="https://d3dnd7j639hmiz.cloudfront.net/uploads/e6a214ca-a046-404f-82fe-af5a82868644.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              onClick={() => {
                navigate("/rules");
              }}
              className="max-w-[80vw] mt-4 flex justify-center w-full items-center"
            >
              <FaInfoCircle className="text-xl" />
              <p className="text-center ml-2">
                <span className="ml-1 font-bold text-yellow-500">
                  {lang?.RULES_GUIDELINES}
                </span>
              </p>
            </div>
            {/* {data?.remainingSeconds} */}
            {/* {data?.openTime && (
            <Timer
              serverTime={data?.serverTime}
              onParticipateClick={() => {
                eventLog("PARTICIPATE", data?.quizId);
                navigate("/videoWithQuiz/" + data?.quizId);
                playClick();
              }}
              onComplete={() => {
                setTimeout(() => {
                  fetchHomeData();
                }, 200);
              }}
              startTime={data?.openTime}
            />
          )} */}
            <>
              <button
                onClick={() => {
                  if (data?.openStatus) {
                    eventLog("PARTICIPATE", data?.quizId);
                    navigate("/videoWithQuiz/" + data?.quizId);
                    playClick();
                  }
                }}
                style={{
                  opacity: data?.openStatus ? 1 : 0.3,
                }}
                className="mt-5 font-bold text-black text-xl bg-yellow-500 w-[300px] py-3 rounded-md"
              >
                {lang?.PARTICIPATE}
              </button>
              {showClaimMoney && (
                <button
                  onClick={() => {
                    navigate("/upi/" + showClaimMoney);
                    playClick();
                  }}
                  className="mt-5 font-bold text-black text-sm bg-white w-[300px] py-3 rounded-md"
                >
                  Claim Today's Winning Money
                </button>
              )}
            </>
            {data?.openStatus === false &&
              data?.openTime &&
              data?.uncompleteCount !== 0 && (
                <p className="text-sm mt-3">
                  Quiz starts at {format(new Date(data?.openTime), "p")}
                </p>
              )}
            {data?.uncompleteCount === 0 && (
              <p className="text-sm mt-3">
                Results will be published before 11:55 PM
              </p>
            )}
            {data &&
              data?.uncompleteCount !== 0 &&
              data?.completedCount &&
              data?.completedCount !== 0 && (
                <p className="text-sm mt-3">
                  {data?.uncompleteCount} Question Pending !
                </p>
              )}
            {/* {data?.openStatus === true && data?.closeTime && (
            <p className="opacity-50 text-sm mt-3">
              Quiz ends at {format(new Date(data?.closeTime), "p")}
            </p>
          )} */}
            {/* <p>Today Quiz Ends at 9 PM</p> */}
            {data?.videoId && data?.openStatus === false && (
              <div className="mt-5 flex justify-center items-center gap-x-4 w-full bg-red-500 p-3 px-4">
                <h1 className="text-xl font-bold max-w-[250px]">
                  {lang?.TODAY_QUIZ}
                </h1>
                <div
                  onClick={() => {
                    window?.JSBridge?.showMessageInNative(
                      JSON.stringify({
                        type: "VIDEO",
                        videoId: data?.videoId,
                        text: "",
                        url: "",
                      })
                    );
                    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
                      JSON.stringify({
                        type: "VIDEO",
                        videoId: data?.videoId,
                        text: "",
                        url: "",
                      })
                    );
                    eventLog("WATCH_NOW_CLICKED", data?.quizId);
                  }}
                  // href="https://pepulstagestage.page.link/PqzkxEngqeCA2zNB6"
                  className="bg-yellow-500 text-center text-black px-4 rounded-md py-2 font-bold"
                >
                  {lang?.WATCH_NOW}
                </div>
              </div>
            )}
          </div>
        ) : (
          searchParams.get("page") === "RESULTS" && <Results />
        )}
      </div>
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 h-screen w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
    </>
  );
};

export default Home;
