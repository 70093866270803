import React from "react";
import axios from "axios";

import { Howl, Howler } from "howler";

import A from "../utils/API";

import useUser from "./useUser";

export default function useUtils() {
  const { headers } = useUser();

  const [sound, setSound] = React.useState(false);

  React.useState(() => {
    const tmp = new Howl({
      src: ["/sounds/click.mp3"],
    });
    setSound(tmp);
  }, []);

  const closeWindow = () => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "CLOSE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "CLOSE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
  };

  const shareScreenshot = (text = "") => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "SCREENSHOT_SHARE",
        videoId: 0,
        text: text,
        url: text,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "SCREENSHOT_SHARE",
        videoId: 0,
        text: text,
        url: text,
      })
    );
  };

  const openUrl = (url = "") => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "OPEN_URL",
        videoId: 0,
        text: "",
        url: url,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "OPEN_URL",
        videoId: 0,
        text: "",
        url: url,
      })
    );
  };

  const saveScreenshot = () => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "SCREENSHOT_SAVE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "SCREENSHOT_SAVE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
  };

  const exitConfirm = () => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "EXIT_CONFIRM",
        videoId: 0,
        text: "Are you sure to exit from quiz ?",
        url: "",
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "EXIT_CONFIRM",
        videoId: 0,
        text: "Are you sure to exit from quiz ?",
        url: "",
      })
    );
  };

  const eventLog = async (name = "", quizId = null) => {
    try {
      const { data } = await axios.post(
        A.HOST + A.QUIZ_LOG,
        {
          eventName: name,
          quizId,
        },
        headers
      );
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const formatMoney = (money) => {
    const formatter = new Intl.NumberFormat("en-US", {
      // style: "currency",
      // currency: "INR",
    });
    return formatter.format(money);
  };

  const playClick = () => {
    sound?.play();
  };

  const playSuccess = () => {
    const sound = new Howl({
      src: ["/sounds/win.wav"],
    });
    sound.play();
  };

  const addSuffix = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const getOS = (window) => {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }

    return operatingSystem;
  };

  return {
    closeWindow,
    eventLog,
    shareScreenshot,
    openUrl,
    saveScreenshot,
    addSuffix,
    exitConfirm,
    getOS,
    playClick,
    playSuccess,
    formatMoney,
  };
}
