import React from "react";

import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import ReactPlayer from "react-player";
import ReactHlsPlayer from "react-hls-player";
import axios from "axios";

import { FcAlarmClock } from "react-icons/fc";
import { FaRupeeSign } from "react-icons/fa";

import { addSeconds, format } from "date-fns";

import { BiCheck, BiRupee } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";

import A from "../../utils/API";

import useLang from "../../hooks/useLang";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import { IoIosArrowBack } from "react-icons/io";
import { BsCheckLg, BsPlus } from "react-icons/bs";

const Timer = React.memo(function ({
  startTime = new Date(),
  onComplete = () => {},
  onParticipateClick = () => {},
}) {
  const navigate = useNavigate();
  const { lang, switchLang, activeLang } = useLang();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      // Render a countdown
      return (
        <div className="ml-2 flex flex-col items-center">
          <div className="flex gap-x-2 my-2">
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                {!Number.isNaN(minutes) && <p>{zeroPad(minutes)}</p>}
                {/* <p className="text-xs text-white opacity-50">{lang?.MINUTES}</p> */}
              </div>
            </p>
            <p>:</p>
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                {!Number.isNaN(seconds) && <p>{zeroPad(seconds)}</p>}
                {/* <p className="text-xs text-white opacity-50">{lang?.SECONDS}</p> */}
              </div>
            </p>
          </div>
          {/* <p className="mt-2 text-xs font-medium">
            {lang?.DAILY_QUIZ} <b>8:00 pm to 8:05 pm</b>
          </p> */}
        </div>
      );
    }
  };
  return (
    <div className="text-2xl font-bold flex gap-x-4">
      <Countdown onComplete={onComplete} date={startTime} renderer={renderer} />
    </div>
  );
});

const VideoPlayer = React.memo(function ({ src = "", thumbnail = "" }) {
  // const [url, setUrl] = React.useState(src);
  const { getOS } = useUtils();

  // React.useEffect(() => {
  //   setUrl(src);
  // }, [src]);

  return (
    <ReactHlsPlayer
      className="mt-5 w-full w-[95vw] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
      src={src}
      poster={thumbnail}
      autoPlay={getOS(window) === "MacOS" ? false : true}
      // controls={getOS(window) === "MacOS"}
      controls={true}
      width="100%"
      style={{
        aspectRatio: 16 / 9,
      }}
    />
    // <video
    //   className="mt-5 w-full w-[95vw] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
    //   controls={false}
    //   autoPlay={true}
    //   muted={false}
    //   playsInline={true}
    // >
    //   <source src={url} type="application/x-mpegURL" />
    // </video>
  );
});

const Campaign = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { headers, setUser, user } = useUser();
  const { activeLang } = useLang();
  const [searchParams, setSearchParams] = useSearchParams();
  const { closeWindow, eventLog, addSuffix, playClick, exitConfirm } =
    useUtils();

  const [showType, setShowType] = React.useState("VIDEO");

  const [selectedAnswer, setSelectedAnswer] = React.useState(false);

  const [data, setData] = React.useState(false);

  const [quizOpen, setQuizOpen] = React.useState(false);

  const [opacity, setOpacity] = React.useState(0);

  const startDate = React.useRef(Date.now());

  const [correctAnswer, setCorrectAnswer] = React.useState(null);

  const [answerLoading, setAnswerLoading] = React.useState(false);

  const [quizStartTime, setQuizStartTime] = React.useState(false);

  const [selectedQuiz, setSelectedQuiz] = React.useState(false);
  const [availableQuiz, setAvailableQuiz] = React.useState(false);
  const [completedQuiz, setCompletedQuiz] = React.useState(false);

  const [answerTypes, setAnswerTypes] = React.useState(false);

  const [subscriptionStatus, setSubscritionStatus] = React.useState(null);

  const [showPopUp, setShowPopUp] = React.useState(false);

  const [timer, setTimer] = React.useState(false);

  const fetchQuiz = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.START_QUIZ_MULTI_CAMPAIGN,
        {},
        headers
      );
      setData(data);
      setAnswerTypes(data?.completedQuiz);
    } catch (err) {
      if (err?.response?.data?.message === "ALREADY_ANSWERED")
        navigate("/alreadyAnswered");
      console.log(err, null, 4);
      closeWindow();
    }
  };

  React.useEffect(() => {
    if (data) {
      if (data?.completedQuiz)
        setCompletedQuiz(data?.completedQuiz?.map((each) => each?.postId));
    }
  }, [data]);

  React.useEffect(() => {
    if (data && Array.isArray(completedQuiz)) {
      setAvailableQuiz(
        data?.questions?.filter(
          (each) => !completedQuiz?.includes(each?.videoId)
        )
      );
    }
  }, [data, completedQuiz]);

  React.useEffect(() => {
    if (Array.isArray(availableQuiz)) {
      if (availableQuiz?.length === 0) {
        navigate("/campaignComplete/" + token, {
          replace: true,
        });
      }
      setSelectedQuiz(availableQuiz?.[0]);
      setSubscritionStatus(availableQuiz?.[0]?.subscriptionStatus);
    }
  }, [availableQuiz]);

  React.useEffect(() => {
    if (selectedQuiz) setShowType("VIDEO");
  }, [selectedQuiz]);

  React.useEffect(() => {
    if (token) setUser({ accessToken: token });
  }, [token]);

  React.useEffect(() => {
    if (user) fetchQuiz();
  }, [user]);

  const initiateAnswer = async (answer) => {
    setAnswerLoading(true);
    setSelectedAnswer(answer);
    try {
      let time = (new Date() - quizStartTime) / 1000;
      const { data } = await axios.post(
        A.HOST + A.ANSWER_QUIZ_MULTI_CAMPAIGN,
        {
          postId: selectedQuiz?.videoId,
          answer: answer,
          langCode: activeLang,
          quizTime: time,
        },
        headers
      );
      setQuizOpen(false);
      navigate(
        `/campaignComplete/` +
          token +
          `?amount=${data?.amount}&result=${data?.rightStatus ? "YES" : "NO"}`,
        {
          replace: true,
          state: {
            link: data?.payoutLink,
          },
        }
      );
      // if (data?.completedQuiz) {
      //   // setAnswerLoading(true);
      //   setCorrectAnswer(data?.rightStatus);
      //   setTimeout(() => {
      //     startDate.current = new Date();
      //     setAnswerLoading(false);
      //     setSelectedAnswer(false);
      //     setCorrectAnswer(null);
      //     setCompletedQuiz(data?.completedQuiz?.map((each) => each?.postId));
      //     setAnswerTypes(data?.completedQuiz);
      //   }, 1000);
      // }
    } catch (err) {
      console.log(err, null, 4);
      closeWindow();
    }
    // setAnswerLoading(false);
  };

  React.useEffect(() => {
    exitConfirm();
  }, []);

  // React.useEffect(() => {
  //   const closeWindow = () =>
  //     setTimeout(() => {
  //       window.open("about:blank", "_self");
  //       window.close();
  //     }, 3000);
  //   closeWindow();
  // }, []);

  React.useEffect(() => {
    if (data !== false)
      setTimeout(() => {
        setOpacity(1);
      }, 200);
  }, [data]);

  return (
    <>
      {data !== false && completedQuiz !== false && (
        <div
          className="h-screen w-screen transition duration-500 ease-out flex flex-col z-10"
          style={{
            opacity: opacity,
          }}
        >
          {/* <IoIosArrowBack
            onClick={() => {
              navigate("/home");
            }}
            className="mt-5 mx-4 fixed text-4xl"
          /> */}
          <h1 className="mt-5 font-bold text-xl text-center">
            Question {completedQuiz?.length + 1}
          </h1>
          <div className="gap-x-2 mt-5 text-xl flex justify-center items-center">
            <div className="w-[90vw] flex justify-center items-center">
              {data?.questions?.map((each, idx) => (
                <>
                  <h1
                    style={{
                      opacity: completedQuiz?.length >= idx ? 1 : 0.3,
                    }}
                    className={`text-black rounded-md font-bold text-sm py-1 px-3 text-lg ${
                      completedQuiz?.length > idx
                        ? answerTypes?.[idx]?.answerType === "RIGHT"
                          ? "bg-green-500 text-white"
                          : "bg-red-500 text-white"
                        : "bg-yellow-500"
                    }`}
                  >
                    {completedQuiz?.length > idx ? (
                      <>
                        {answerTypes?.[idx]?.answerType !== "RIGHT" ? (
                          <ImCross className="text-md my-1" />
                        ) : (
                          <BsCheckLg className="text-md my-1" />
                        )}
                      </>
                    ) : (
                      <p>{idx + 1}</p>
                    )}
                  </h1>
                  <div
                    style={{
                      opacity: completedQuiz?.length > idx ? 1 : 0.3,
                    }}
                    className="h-1 w-[10%] bg-white"
                  ></div>
                </>
              ))}
              <img
                style={{
                  opacity: 0.5,
                }}
                className="h-10 min-w-[30px] object-cover"
                src="/img/rupees.png"
                alt=""
              />
            </div>
          </div>
          {showType === "VIDEO" ? (
            <div className="flex h-full justify-center flex-col w-full items-center">
              {/* <p className="mt-4">Question will reveal after the Timer</p> */}
              <p className="max-w-[80vw] text-center text-md">
                இந்த வீடியோவில் இருந்து கேள்வி கேட்கப்படும்
              </p>
              <VideoPlayer
                thumbnail={selectedQuiz?.videoThumbnail}
                src={selectedQuiz?.videoUrl}
              />
              <div className="flex items-center justify-between w-full px-5 mt-3">
                <div className="flex items-center">
                  <div className="relative h-12 w-12">
                    <img
                      className="absolute h-12 w-12 bg-blue-800 inset-0 border-2 border-yellow-500 object-cover rounded-md"
                      src={selectedQuiz?.creatorImage}
                      alt=""
                    />
                    <img
                      className="absolute z-10 right-0 -m-1 top-0"
                      src="/img/star.png"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    {selectedQuiz?.creatorName && (
                      <p className="-mt-1 text-sm font-bold">
                        {selectedQuiz?.creatorName?.substring(0, 30)}
                      </p>
                    )}
                    {selectedQuiz?.creatorCategory && (
                      <div className="flex">
                        <p
                          className="text-xs px-2 py-1"
                          style={{
                            fontSize: 10,
                            borderWidth: 1,
                          }}
                        >
                          {selectedQuiz?.creatorCategory}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* {subscriptionStatus !== null && (
                  <div className="">
                    {subscriptionStatus ? (
                      <div className="flex items-center rounded-full px-2 py-1 md:py-2  md:px-4 bg-blue-800">
                        <BiCheck className="text-2xl" />
                        <p>Subscribed</p>
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          subscribeCreator(selectedQuiz?.creatorId)
                        }
                        className="flex items-center rounded-full px-2 py-1 md:py-2  md:px-4 bg-green-500"
                      >
                        <BsPlus className="text-2xl" />
                        <p>Subscribe</p>
                      </div>
                    )}
                  </div>
                )} */}
              </div>

              <button
                onClick={() => {
                  setShowType("QUIZ");
                  setQuizStartTime(new Date());
                  // eventLog("TAKE_QUIZ", id);
                }}
                className="mt-5 rounded-md w-[80vw] bg-yellow-500 text-black text-lg font-bold py-4"
              >
                Take Quiz
              </button>
            </div>
          ) : (
            <div className="mt-10 flex justify-center flex-col w-full items-center">
              <div className="py-2">
                <h3 className="max-w-[600px] text-center indent-3 text-lg rounded-xl bg-white text-black font-bold mx-5 p-4">
                  {selectedQuiz?.question}
                </h3>
              </div>
              <div className="flex flex-col items-center w-full max-w-[600px] mx-3 px-5 pt-4 text-xl font-bold">
                {selectedQuiz?.answers?.map((each) => (
                  <button
                    onClick={() => {
                      if (answerLoading === false) {
                        initiateAnswer(each);
                        // eventLog("ANSWER_CLICK", id);
                        playClick();
                      }
                    }}
                    disabled={answerLoading}
                    className="border rounded-md w-full px-3 h-14 my-2 pl-4 text-left"
                    style={{
                      backgroundColor:
                        correctAnswer === true && selectedAnswer === each
                          ? "green"
                          : correctAnswer === false && selectedAnswer === each
                          ? "red"
                          : undefined,
                      opacity:
                        answerLoading === true && selectedAnswer !== each
                          ? 0.5
                          : 1,
                    }}
                  >
                    {each}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
    </>
  );
};

export default Campaign;
