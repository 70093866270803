import React from "react";
import axios from "axios";

import useLang from "../hooks/useLang";
import useUtils from "../hooks/useUtils";

import A from "../utils/API";

import { differenceInDays, differenceInHours, format, subDays } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BiCalendarEvent, BiRupee } from "react-icons/bi";
import { BiCloudDownload } from "react-icons/bi";
import { FiRotateCw, FiShare2 } from "react-icons/fi";
import useUser from "../hooks/useUser";

const Loading = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowLoading(true);
    }, 1000);
  }, []);

  return showLoading ? (
    <div className="w-full mt-10 flex justify-center items-center">
      <FiRotateCw className="mr-2 animate-spin" />
      <p>Loading</p>
    </div>
  ) : null;
};

export default function Results() {
  const { lang } = useLang();
  const { eventLog } = useUtils();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { headers, user } = useUser();
  const { shareScreenshot, saveScreenshot, formatMoney } = useUtils();
  const { addSuffix } = useUtils();
  const [parti, setParti] = React.useState(false);

  const [page, setPage] = React.useState("HISTORY");

  const [selectedDate, setSelectedDate] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [opacity, setOpacity] = React.useState(0);

  const fetchParticipations = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        A.HOST + A.RECENT_PARTICIPATENTS,
        {
          limit: 10,
        },
        headers
      );
      setParti(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (parti && parti.length > 0 && !searchParams.get("show")) {
      const selectable = parti?.find((each) => each?.answerOrder);
      if (selectable) {
        setPage("WINNER");
        setSelectedDate(selectable);
      }
    }
  }, [parti]);

  React.useEffect(() => {
    fetchParticipations();
  }, []);

  React.useEffect(() => {
    if (selectedDate) setPage("WINNER");
  }, [selectedDate]);

  React.useEffect(() => {
    if (parti !== false)
      setTimeout(() => {
        setOpacity(1);
      }, 200);
  }, [parti]);

  return parti !== false ? (
    <div
      className="transition duration-300"
      style={{
        opacity: opacity,
      }}
    >
      {selectedDate && (
        <div
          className="mt-4 flex p-3 flex justify-center items-center"
          style={{
            backgroundColor: "#072B61",
          }}
        >
          <p
            onClick={() => {
              setPage("WINNER");
            }}
            className={
              page === "WINNER"
                ? "m-1 py-1 bg-white px-3 text-black rounded-full"
                : "px-3"
            }
          >
            {new Date().getMonth() ===
              new Date(selectedDate?.dateTime)?.getMonth() &&
            new Date().getDate() -
              new Date(selectedDate?.dateTime).getDate() ===
              0
              ? "Today"
              : new Date().getMonth() ===
                  new Date(selectedDate?.dateTime)?.getMonth() &&
                new Date().getDate() -
                  new Date(selectedDate?.dateTime).getDate() ===
                  1
              ? "Yesterday"
              : format(new Date(selectedDate?.dateTime), "PP")}
          </p>
          <p
            onClick={() => {
              setPage("HISTORY");
            }}
            className={
              page === "HISTORY"
                ? "m-1 py-1 bg-white px-3 text-black rounded-full"
                : "px-3"
            }
          >
            History
          </p>
        </div>
      )}
      {page === "WINNER" && (
        <div className="flex flex-col items-center">
          <img className="mt-3 h-24" src="/img/quiz.png" alt="" />
          <img className="mt-5 h-14" src="/img/winner.png" alt="" />
          <div className="my-4 h-52 relative w-full">
            <div className="absolute inset-0 flex justify-center items-center">
              <img
                className="h-48 w-48 border-4 bg-black border-dashed border-yellow-500 rounded-full object-cover"
                src={user?.userImage}
                alt=""
              />
            </div>
            <div className="absolute inset-0 flex justify-center items-end">
              <img className="mt-5 h-16" src="/img/greeting.png" alt="" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center mb-3">
              <BiRupee className="text-xl text-yellow-800" />
              <p className="text-xl text-yellow-800 font-bold">
                {formatMoney(selectedDate?.amount)}
              </p>
            </div>
          </div>
          <p className="mt-2 text-sm text-yellow-400 font-bold">
            {addSuffix(selectedDate?.answerOrder)} Winner
          </p>
          {selectedDate?.dateTime && (
            <p className="flex items-center opacity-75 font-bold">
              <BiCalendarEvent />
              <p className="ml-1">
                {format(new Date(selectedDate?.dateTime), "PP")}
              </p>
            </p>
          )}
          <div
            className="mx-5 w-full relative"
            style={{
              height: 100,
            }}
          >
            <p className="text-lg font-bold absolute inset-0 flex justify-center items-center z-10">
              {user?.userName}
            </p>
            <div className="absolute w-full h-full flex justify-center items-center">
              <img
                style={{
                  width: "75%",
                }}
                src="/img/bg-banner.png"
                alt=""
              />
            </div>
          </div>
          <img className="mt-2 h-10" src="/img/pepul-logo.svg" alt="" />
          <p className="max-w-[80vw] text-center text-xs opacity-75 mt-4">
            Sharing increases the chance of getting 1st Prize for future
            contests
          </p>
          <div className="flex flex-col mb-10">
            <button
              onClick={() => {
                // shareScreenshot();
                navigate("/share", {
                  state: { ...selectedDate, type: "SHARE" },
                });
                eventLog("SHARE_CLICK");
              }}
              className="w-[80vw] flex justify-center text-md items-center mx-2 mt-5 font-bold text-white text-md bg-blue-800 px-8 py-3 rounded-md"
            >
              <FiShare2 className="text-2xl" />
              <p className="ml-2">Share to your friends</p>
            </button>
            <button
              onClick={() => {
                navigate("/share", {
                  state: { ...selectedDate, type: "SAVE" },
                });
                eventLog("SAVE_CLICK");
              }}
              className="w-[80vw] flex justify-center text-md items-center mx-2 mt-5 font-bold text-black text-md bg-yellow-500 px-8 py-3 rounded-md"
            >
              <BiCloudDownload className="text-2xl" />
              <p className="ml-1">Save to Gallery</p>
            </button>
          </div>
        </div>
      )}
      {page === "HISTORY" && parti && parti?.length > 0 ? (
        <>
          <h1 className="text-center text-lg font-bold text-yellow-500 mt-5">
            {lang?.WINNING_HISTORY}
          </h1>
          {/* {selectedDate && (
            <p className="text-center text-sm">Tap for more info</p>
          )} */}
          <table className="max-w-[100vw] mt-1 w-full">
            {parti?.map((each, index) => (
              <tr
                onClick={() => {
                  if (each?.answerOrder) {
                    setSelectedDate(each);
                    setPage("WINNER");
                  }
                  eventLog("RESULT_SINGLE_CLICK");
                }}
                className={
                  index % 2 === 0
                    ? "bg-blue-500 bg-opacity-25"
                    : "bg-blue-400 bg-opacity-25"
                }
              >
                <td>
                  <p className="ml-3 flex justify-center font-bold text-md py-3 p-2">
                    {each?.answerOrder ? addSuffix(each?.answerOrder) : null}
                  </p>
                </td>
                <td>
                  <div className="py-3 p-2 flex items-center max-w-[200px] overflow-x-hidden">
                    <img
                      className="border-2 h-12 w-12 object-cover rounded-full"
                      src={each?.userImage}
                      alt=""
                    />
                    <div className="ml-2 flex flex-col">
                      <h1 className="text-md font-bold">{each?.userName}</h1>
                      <p className="text-sm">
                        {each?.dateTime
                          ? format(new Date(each?.dateTime), "PP")
                          : null}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  {each?.amount ? (
                    <p className="text-yellow-500 py-3 flex items-center p-2 px-1 text-xl font-bold">
                      <BiRupee />
                      <p>{each?.amount}</p>
                    </p>
                  ) : (
                    <p className="text-xs">{lang?.ANOUNCE_SOON}</p>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </>
      ) : null}
      {page === "HISTORY" && parti && parti?.length === 0 && (
        <p className="text-center my-10">No history to show</p>
      )}
    </div>
  ) : (
    <Loading />
  );
}
