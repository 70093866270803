import React from "react";

import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

// import useUtils from "../../hooks/useUtils";

export default function Rules() {
  const navigate = useNavigate();
  // const { getOS } = useUtils();
  React.useEffect(() => {
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
      <div className="min-h-screen w-screen overflow-y-screen">
        <div className="">
          <div className="py-5 flex items-center">
            <IoIosArrowBack
              //   onClick={() => {
              //     closeWindow();
              //   }}
              onClick={() => {
                navigate(-1);
              }}
              className="ml-2 text-4xl"
            />
            <h1 className="text-xl ml-2">Rules & Contest Guidelines</h1>
          </div>
          <div className="px-4 text-md my-2 mx-2">
            <p>
              Following are the rules and disclaimers applicable to user
              participants with respect to the quiz contest:
            </p>
            <ul className="flex flex-col gap-y-2 mt-4 list-disc">
              <li className="ml-2">
                Participation in the Quiz Contest is free - Here no cost to pay
              </li>
              <li className="ml-2">
                The quiz questions shall be strictly based on the content
                displayed on this platform through the platform channel/page of
                the content creator.
              </li>
              <li className="ml-2">
                The winners are chosen in a manner that is computer generated
                and the said selection shall be on a first-come, first answer
                basis.
              </li>
              <li className="ml-2">
                Prizes for the quiz contest shall range from direct cash prizes
                ranging from INR 1 to INR 10,000.
              </li>
              <li className="ml-2">
                This Quiz Contest would amount to your participation in a game
                of skill. By no means would your participation in this Quiz
                Contest be deemed as a participation in a game of chance, as the
                said quiz contest requires a user to exercise his/her skill to
                arrive at an answer.
              </li>
              <li className="ml-2">
                In this Quiz contest, Apple is not a sponsor and not involved in
                any way with the contest.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
