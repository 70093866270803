import React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useLang from "../../hooks/useLang";

import { BiRupee, BiShapeSquare } from "react-icons/bi";

import useUtils from "../../hooks/useUtils";
import A from "../../utils/API";

export default function Upi() {
  const { user, headers } = useUser();
  const navigate = useNavigate();
  const { lang } = useLang();

  const { closeWindow, eventLog, playClick } = useUtils();
  const { id } = useParams();

  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [checkbox, setCheckBox] = React.useState(true);

  const [complete, setComplete] = React.useState(false);

  const addUPI = async () => {
    if (!validUPI) return;
    setLoading(true);
    try {
      const { data } = await axios.post(
        A.HOST + A.UPDATE_UPI,
        {
          quizId: id,
          upi: text,
        },
        headers
      );
      setComplete(true);
    } catch (err) {
      closeWindow();
      // alert(JSON.stringify(err, null, 4));
    }
    setLoading(false);
  };

  // React.useEffect(() => {
  //   if (complete)
  //     setTimeout(() => {
  //       closeWindow();
  //     }, 3000);
  // }, [complete]);

  React.useEffect(() => {
    eventLog("UPI_PAGE_OPENED");
  }, []);

  const validUPI = () => /^[\w.-]+@[\w.-]+$/.test(text);

  return (
    <>
      {complete === false ? (
        <div className="flex flex-col items-left bg-blue-500 min-h-screen my-auto px-5 py-10">
          <div className=" mb-5 flex items-center">
            {/* <BiRupee className="text-2xl" /> */}
            <h2 className="text-xl font-bold">{lang?.PAYMENT_INFO}</h2>
          </div>
          <p className="max-w-[300px] text-gray-400 mb-5">
            {lang?.CREDIT_ACCOUNT}
          </p>
          <div className="flex flex-col">
            <label className="">{lang?.ENTER_UPI}</label>
            <input
              type="text"
              name=""
              autoCapitalize="none"
              value={text}
              onChange={(e) => setText(e.target.value?.trim())}
              id=""
              placeholder="UPI ID"
              className="bg-[#30305c] my-3 px-3 border w-[90vw] h-12 outline-none border-gray-400 rounded-md"
            />
            <div className="flex items-center">
              <input
                onChange={(e) => setCheckBox(!checkbox)}
                checked={checkbox}
                type="checkbox"
                name=""
                id=""
              />
              <p className="ml-2">{lang?.VALID_UPI}</p>
            </div>
            <button
              onClick={() => {
                if (validUPI() && checkbox) {
                  addUPI();
                  eventLog("UPI_SUBMIT");
                  playClick();
                }
              }}
              style={{
                opacity: validUPI() && checkbox ? 1 : 0.5,
              }}
              disabled={!validUPI() && checkbox}
              className="mt-2 font-bold border-none bg-yellow-500 text-black text-lg w-[90vw] h-12 rounded-md"
            >
              {lang?.SUBMIT}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center min-h-screen my-auto">
          <img
            src="/img/dialog-bg.jpeg"
            className="fixed w-screen object-cover inset-0 bg-blue-500"
            style={{
              zIndex: -20,
            }}
          />
          {/* <div
            style={{
              zIndex: -1,
            }}
            className="fixed opacity-75 h-screen w-screen bg-blue-500"
          ></div> */}
          <img className="mt-24 h-32" src="/img/quiz.png" alt="" />
          {/* <img className="h-32" src="/img/success.gif" alt="" /> */}
          <h2 className="mt-10 text-white text-center font-bold text-xl sm:text-lg mb-5">
            🎉 Thank you <br /> for your participation 🎉
          </h2>
          <p className="max-w-[80vw] text-center text-sm">
            The Winner's list will be announced before 11:55 PM. Your prize
            money will be credited to your given UPI account in 2 to 3 days.
            Keep enjoying Pepul with your family and friends.
          </p>
          <p className="max-w-[80vw] opacity-50 text-center mt-5 ">
            <span>
              Sharing the previous contest's results will increase the chance of
              getting 1st Prize
            </span>
          </p>
          <button
            onClick={() => {
              navigate("/home?page=RESULTS&show=HISTORY");
            }}
            className="mt-10 font-bold text-black text-md bg-yellow-500 w-[300px] py-3 rounded-md"
          >
            See Results and Share
          </button>
        </div>
      )}
    </>
  );
}
