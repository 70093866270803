const MODE = "LIVE"; // LOCAL, STAGE, LIVE, TEST

const API = {};

if (MODE === "LOCAL") API["HOST"] = "http://localhost:3001";
if (MODE === "STAGE") API["HOST"] = "https://creatornode.hifrds.com";
// if (MODE === "LIVE") API["HOST"] = "http://192.168.29.162:3001";
if (MODE === "TEST") API["HOST"] = "https://creatortest.hifrds.com";
if (MODE === "LIVE") API["HOST"] = "https://pc.nxtgensn.in";
if (MODE === "DEMO") API["HOST"] = "https://creatordemo.hifrds.com";

API.MODE = MODE;
// API.GET_QUIZ = "/api/v35/creatorService/quiz/getQuiz";
API.VALIDATE_USER = "/api/v35/creatorService/quiz/validateUserForQuiz";
API.HOME_DATA = "/api/v35/creatorService/quiz/getHomeData";
API.START_QUIZ = "/api/v35/creatorService/quiz/startQuiz";
API.ANSWER_QUIZ = "/api/v35/creatorService/quiz/answerQuiz";
API.UPDATE_UPI = "/api/v35/creatorService/quiz/updateUPI";
API.QUIZ_TIMEOUT = "/api/v35/creatorService/quiz/timeUpQuiz";
API.QUIZ_LOG = "/api/v35/creatorService/quiz/quizLog";
API.LEADERBOARD = "/api/v35/creatorService/quiz/getLeaderBoard";
API.RECENT_PARTICIPATENTS = "/api/v35/creatorService/quiz/recentParticipations";
API.HOME_DATA_MULTI = "/api/v35/creatorService/quiz/getHomeDataMulti";
API.START_QUIZ_MULTI = "/api/v35/creatorService/quiz/startQuizMulti";
API.ANSWER_QUIZ_MULTI = "/api/v35/creatorService/quiz/answerQuizMulti";
API.ANSWER_RESULT_MULTI = "/api/v35/creatorService/quiz/getResultMulti";
API.SUBSCRIBE_CREATOR =
  "/api/v35/creatorService/subscriptions/subscribeCreator";

// CAMPAIGN
API.START_QUIZ_MULTI_CAMPAIGN =
  "/api/v35/creatorService/quiz/startQuizMultiCampaign";
API.ANSWER_QUIZ_MULTI_CAMPAIGN =
  "/api/v35/creatorService/quiz/answerQuizMultiCampaign";

// if (MODE === "LIVE")
//   API["PHP_UPDATE"] =
//     "https://nxtgensn.in/dev/API/75934107_release_v35/participate_log.php";

export default API;
